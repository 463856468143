import { Order } from '@medusajs/medusa';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';

import OrderContext from '../context/order-context';
import { QUERY_KEYS } from '../services/queryKeys';

export const useOrder = ({ displayId }: { displayId?: string }) => {
  const context = useContext(OrderContext);

  const { data: orderResponse, isLoading: isOrderLoading } = useQuery({
    queryKey: [QUERY_KEYS.order.get, 'display_id', displayId],
    queryFn: () => axios.get<Order>(`/store/orders/${displayId}`),
    enabled: !!displayId,
  });

  if (!context) {
    throw new Error(
      'useOrder hook was used but a OrderContext.Provider was not found in the parent tree. Make sure this is used in a component that is a child of OrderProvider'
    );
  }

  return { ...context, order: orderResponse?.data, isOrderLoading };
};
