import React, { useEffect, useState } from 'react';

import { useOrder } from '../../hooks/use-order';
import { Order } from '../../types/Order';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AccountLayout from '../account/AccountLayout';
import MainLayout from '../layout/MainLayout';
import BasePagination from '../pagination/BasePagination';
import OrderEmpty from './OrderEmpty';
import OrderTable from './OrderTable';

const OrderHistoryPage = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    pageSize,
    actions: { listOrders },
  } = useOrder({});

  const fetchOrder = async (offset?: number) => {
    try {
      setLoading(true);
      const res = await listOrders(offset);
      setOrders(res.orders);
      setTotal(res.count);
    } catch (_) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const onChangePage = (page: number) => {
    setCurrentPage(page);
    fetchOrder((page - 1) * pageSize);
  };

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'คำสั่งซื้อ',
            link: '/orders',
          },
        ]}
        title="ประวัติการสั่งซื้อ"
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="rounded-[6px] border border-light-divider bg-neutral-1 p-6 shadow-small">
            {orders.length > 0 || loading ? (
              <>
                <OrderTable
                  loading={loading}
                  orders={orders}
                  orderPath="/orders"
                />
                <BasePagination
                  total={total}
                  pageSize={pageSize}
                  current={currentPage}
                  onChange={onChangePage}
                />
              </>
            ) : (
              <OrderEmpty />
            )}
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default OrderHistoryPage;
